/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "normalize.css"
import "./layout.css"

const Layout = ({ hamburger, fixedHeader, children }) => {
  return (
    <>
      <Header hamburger={hamburger} fixed={fixedHeader} />
      {children}
    </>
  )
}

Layout.defaultProps = {
  hamburger: "default",
  fixedHeader: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
