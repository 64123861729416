import React, { useState } from "react"
import imgLogo from "../images/logo.png"
import styles from "./header.module.css"
import Menu from "./menu"
import { Link } from "gatsby"

const Logo = ({ fixed }) => (
  <img
    src={imgLogo}
    alt="Logo"
    className={styles.logo + (fixed ? ` ${styles.fixed}` : "")}
  />
)

Logo.defaultProps = {
  fixed: false
}

const Hamburger = ({ onClick, className, type, fixed }) => {
  return (
    <button
      className={
        `${styles.hamburger} ${className} ${styles[type]}` +
        (fixed ? ` ${styles.fixed}` : ``)
      }
      onClick={onClick}
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  )
}

Hamburger.defaultProps = {
  className: "",
  fixed: false,
}

const Header = ({ hamburger, fixed }) => {
  const [menuVisible, setMenuVisible] = useState(false)

  const handleClick = () => {
    setMenuVisible(!menuVisible)
  }

  return (
    <>
      <Link to="/">
        <Logo fixed={fixed} />
      </Link>
      <Hamburger
        type={hamburger}
        onClick={handleClick}
        className={menuVisible ? styles.close : ""}
        fixed={fixed}
      />
      <Menu visible={menuVisible} />
    </>
  )
}

Header.defaultProps = {
  fixed: false,
}

export default Header