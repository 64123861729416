import React, { useEffect } from "react"
import styles from "./menu.module.css"
import { Link } from "gatsby"
import Typo from "./typo"
import IconArrowRight from "../images/icon-arrow-right.svg"

const items = [
  {
    name: "WORK",
    description: "Let our work do the talking. ^_^",
    button: "Go to Work",
    to: "/work"
  },
  {
    name: "ABOUT",
    description:
      "We help bold companies design, build, and launch digital products and services.",
    button: "Luv You",
    to: "/about"
  },
  {
    name: "CONTACT",
    description:
      '<a href="tel:+85237026910">+852 3702 6910</a><br /><a href="mailto:mimosa.lee@ultd.com.hk">mimosa.lee@ultd.com.hk</a>',
    button: "Let's Talk",
    to: "/contact"
  },
]

const Item = ({ name, description, button, to }) => (
  <li className={styles.item}>
    <div>
      <Typo type="title500" component="div" className={styles.item__name}>
        <span>{name}</span>
      </Typo>
      <Typo
        type="text300"
        component="div"
        className={styles.item__description}
        dangerouslySetInnerHTML={{ __html: description }}
      ></Typo>
      <div className={styles.item__button}>
        <Typo type="text600" component={Link} to={to}>
          {button}
          <IconArrowRight />
        </Typo>
      </div>
    </div>
  </li>
)

const Menu = ({ visible }) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.removeAttribute("style")
    }
  }, [visible])

  return (
    <ul className={styles.root + (visible ? ` ${styles.in}` : "")}>
      {items.map((item, i) => (
        <Item key={i} {...item} />
      ))}
    </ul>
  )
}

export default Menu