import React from "react"
import styles from "./typo.module.css"

const Wrapper = ({ Component, children, ...props }) => (
  <Component {...props}>{children}</Component>
)

const Typo = ({ type, component, className, children, ...props }) => (
  <Wrapper
    Component={component}
    className={`${styles[type]} ${className}`}
    {...props}
  >
    {children}
  </Wrapper>
)

Typo.defaultProps = {
  type: "text",
  component: "p",
  className: ""
}

export default Typo